import Axios from 'axios';

import {
	CLEAR_ALL_USERS_DATA,
	CLEAR_ALL_USERS_ERRORS,
	CLEAR_USERS_ERROR,
	SEARCH_USER_LOADING,
	SEARCH_USER_GET,
	SEARCH_USER_ERROR,
	USER_INFO_LOADING,
	USER_INFO_GET,
	USER_INFO_ERROR
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

export const clear_users_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_USERS_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_USERS_ERRORS
		});
	}
}

export const search_user = (filters) => dispatch => {
	dispatch({
		type: SEARCH_USER_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/users/search?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		const newUserArray = add_key_prop(res.data.users);
		res.data.users = newUserArray;

		dispatch({
			type: SEARCH_USER_GET,
			payload: res.data
		});

		dispatch({
			type: SEARCH_USER_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: SEARCH_USER_ERROR,
			payload: { type: 'search_user', msg: err.message }
		});

		dispatch({
			type: SEARCH_USER_LOADING,
			payload: false
		});
	})
}

export const get_user_info = (user_id) => dispatch => {
	dispatch({
		type: USER_INFO_LOADING,
		payload: true
	});
	
	let url = process.env.REACT_APP_SERVER_URL + `/api/users/${user_id}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: USER_INFO_GET,
			payload: res.data
		});

		dispatch({
			type: USER_INFO_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: USER_INFO_ERROR,
			payload: { type: 'get_user_info', msg: err.message }
		});

		dispatch({
			type: USER_INFO_GET,
			payload: {}
		});

		dispatch({
			type: USER_INFO_LOADING,
			payload: false
		});
	})
}
