import {
	CLEAR_ALL_STAFFS_DATA,
	CLEAR_ALL_STAFFS_ERRORS,
	CLEAR_STAFFS_ERROR,
	STAFF_CHECK_LOADING,
	STAFF_CHECK_SUCCESS,
	STAFF_CHECK_ERROR
} from '../types';

const initialState = {
	staff_check_loading: false,
	user_staff: {},
	staffs_errors: {}
}

export default function staffsReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_STAFFS_ERRORS:
			return {
				...state,
				staffs_errors: {}
			}
		case CLEAR_STAFFS_ERROR:
			return {
				...state,
				staffs_errors: Object.keys(state.staffs_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.staffs_errors[key];
					}
					return acc;
				}, {})
			}
		case STAFF_CHECK_LOADING:
			return {
				...state,
				staff_check_loading: action.payload
			}
		case STAFF_CHECK_SUCCESS:
			return {
				...state,
				user_staff: action.payload
			}
		case STAFF_CHECK_ERROR:
			return {
				...state,
				staffs_errors: {
					...state.staffs_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
