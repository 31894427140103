// User
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_INIT = 'USER_INIT';
export const USER_INIT_CORRECT = 'USER_INIT_CORRECT';
export const USER_INIT_ERROR = 'USER_INIT_ERROR';

export const CLEAR_ALL_USERS_DATA = 'CLEAR_ALL_USERS_DATA';
export const CLEAR_ALL_USERS_ERRORS = 'CLEAR_ALL_USERS_ERRORS';
export const CLEAR_USERS_ERROR = 'CLEAR_USERS_ERROR';

export const SEARCH_USER_LOADING = 'SEARCH_USER_LOADING';
export const SEARCH_USER_GET = 'SEARCH_USER_GET';
export const SEARCH_USER_ERROR = 'SEARCH_USER_ERROR';

export const USER_INFO_LOADING = 'USER_INFO_LOADING';
export const USER_INFO_GET = 'USER_INFO_GET';
export const USER_INFO_ERROR = 'USER_INFO_ERROR';

// Organization
export const ORGANIZATIONS_GET = 'ORGANIZATIONS_GET';
export const ORGANIZATIONS_ERROR = 'ORGANIZATIONS_ERROR';
export const ORG_INFO_GET = 'ORG_INFO_GET';
export const ORG_INFO_ERROR = 'ORG_INFO_ERROR';

export const WORKSPACES_GET = 'WORKSPACES_GET';
export const WORKSPACES_ERROR = 'WORKSPACES_ERROR';

export const MODULES_GET = 'MODULES_GET';
export const MODULES_ERROR = 'MODULES_ERROR';

export const CYCLES_GET = 'CYCLES_GET';
export const CYCLES_ERROR = 'CYCLES_ERROR';

export const ORG_FILTERS_SET = 'ORG_FILTERS_SET';

// Cycles
export const CLEAR_ALL_CYCLES_DATA = 'CLEAR_ALL_CYCLES_DATA';
export const CLEAR_ALL_CYCLES_ERRORS = 'CLEAR_ALL_CYCLES_ERRORS';
export const CLEAR_CYCLES_ERROR = 'CLEAR_CYCLES_ERROR';

export const SET_CYCLE_ID = 'SET_CYCLE_ID';
export const CYCLE_CREATE_LOADING = 'CYCLE_CREATE_LOADING';
export const CYCLE_CREATE_ERROR = 'CYCLE_CREATE_ERROR';

export const ALL_CYCLES_LOADING = 'ALL_CYCLES_LOADING';
export const ALL_CYCLES_GET = 'ALL_CYCLES_GET';
export const ALL_CYCLES_ERROR = 'ALL_CYCLES_ERROR';

export const CYCLE_UPDATE_LOADING = 'CYCLE_UPDATE_LOADING';
export const CYCLE_UPDATE_ERROR = 'CYCLE_UPDATE_ERROR';

export const CYCLE_DELETE_LOADING = 'CYCLE_DELETE_LOADING';

// Areas
export const AREAS_GET = 'AREAS_GET';
export const AREAS_ERROR = 'AREAS_ERROR';

export const REGIONS_GET = 'REGIONS_GET';
export const REGIONS_ERROR = 'REGIONS_ERROR';
export const REGION_INFO_GET = 'REGION_INFO_GET';
export const REGION_INFO_ERROR = 'REGION_INFO_ERROR';

export const ZONES_GET = 'ZONES_GET';
export const ZONES_ERROR = 'ZONES_ERROR';
export const ZONE_INFO_GET = 'ZONE_INFO_GET';
export const ZONE_INFO_ERROR = 'ZONE_INFO_ERROR';

export const DEPOTS_GET = 'DEPOTS_GET';
export const DEPOTS_ERROR = 'DEPOTS_ERROR';
export const DEPOT_INFO_GET = 'DEPOT_INFO_GET';
export const DEPOT_INFO_ERROR = 'DEPOT_INFO_ERROR';

export const GROUPS_GET = 'GROUPS_GET';
export const GROUPS_ERROR = 'GROUPS_ERROR';
export const GROUP_INFO_GET = 'GROUP_INFO_GET';
export const GROUP_INFO_ERROR = 'GROUP_INFO_ERROR';

export const AREA_FILTERS_SET = 'AREA_FILTERS_SET';

// Self
export const CLEAR_SELF_ERRORS = 'CLEAR_SELF_ERRORS';

export const UPLOAD_FILE_ERROR = 'UPLOAD_FILE_ERROR';

export const ALL_FILES_LOADING = 'ALL_FILES_LOADING';
export const ALL_FILES_GET = 'ALL_FILES_GET';
export const ALL_FILES_ERROR = 'ALL_FILES_ERROR';

export const FILE_INFO_LOADING = 'FILE_INFO_LOADING';
export const FILE_INFO_GET = 'FILE_INFO_GET';
export const FILE_INFO_ERROR = 'FILE_INFO_ERROR';

export const DOWNLOAD_FILE_LOADING = 'DOWNLOAD_FILE_LOADING';

export const FILE_CHECK_LOADING = 'FILE_CHECK_LOADING';
export const FILE_CHECK_GET = 'FILE_CHECK_GET';
export const FILE_CHECK_ERROR = 'FILE_CHECK_ERROR';

export const DOWNLOAD_VALIDATION_LOADING = 'DOWNLOAD_VALIDATION_LOADING';

export const FILE_CONFIRM_LOADING = 'FILE_CONFIRM_LOADING';

export const FILE_DISCARD_LOADING = 'FILE_DISCARD_LOADING';

export const LOAD_CREATE_LOADING = 'LOAD_CREATE_LOADING';
export const LOAD_CREATE_ERROR = 'LOAD_CREATE_ERROR';

export const ALL_LOADS_LOADING = 'ALL_LOADS_LOADING';
export const ALL_LOADS_GET = 'ALL_LOADS_GET';
export const ALL_LOADS_ERROR = 'ALL_LOADS_ERROR';

export const LOAD_UPDATE_LOADING = 'LOAD_UPDATE_LOADING';
export const LOAD_UPDATE_ERROR = 'LOAD_UPDATE_ERROR';

export const LOAD_DELETE_LOADING = 'LOAD_DELETE_LOADING';

export const LOADS_DATA_LOADING = 'LOADS_DATA_LOADING';
export const LOADS_DATA_GET = 'LOADS_DATA_GET';
export const LOADS_DATA_ERROR = 'LOADS_DATA_ERROR';

// Routes
export const ROUTES_LOADING = 'ROUTES_LOADING';
export const ROUTES_GET = 'ROUTES_GET';
export const ROUTES_ERROR = 'ROUTES_ERROR';

export const ROUTE_DATA_LOADING = 'ROUTE_DATA_LOADING';
export const ROUTE_DATA_GET = 'ROUTE_DATA_GET';
export const ROUTE_DATA_ERROR = 'ROUTE_DATA_ERROR';

export const ROUTE_LOCATIONS_LOADING = 'ROUTE_LOCATIONS_LOADING';
export const ROUTE_LOCATIONS_GET = 'ROUTE_LOCATIONS_GET';
export const ROUTE_LOCATIONS_ERROR = 'ROUTE_LOCATIONS_ERROR';

export const ROUTE_CYCLE_GET = 'ROUTE_CYCLE_GET';
export const ROUTE_CYCLE_ERROR = 'ROUTE_CYCLE_ERROR';

// Self Errors
export const CLEAR_SELF_ERRORS_ERRORS = 'CLEAR_ERRORS_ERRORS';

export const SELF_ERROR_CREATE_LOADING = 'SELF_ERROR_CREATE_LOADING';
export const SELF_ERROR_CREATE_ERROR = 'SELF_ERROR_CREATE_ERROR';

export const ALL_SELF_ERRORS_LOADING = 'ALL_SELF_ERRORS_LOADING';
export const ALL_SELF_ERRORS_GET = 'ALL_SELF_ERRORS_GET';
export const ALL_SELF_ERRORS_ERROR = 'ALL_SELF_ERRORS_ERROR';

export const SELF_ERROR_UPDATE_LOADING = 'SELF_ERROR_UPDATE_LOADING';
export const SELF_ERROR_UPDATE_ERROR = 'SELF_ERROR_UPDATE_ERROR';

export const SELF_ERROR_DELETE_LOADING = 'SELF_ERROR_DELETE_LOADING';

// Self Schemas
export const CLEAR_SELF_SCHEMAS_ERRORS = 'CLEAR_SCHEMAS_ERRORS';

export const SELF_SCHEMA_CREATE_LOADING = 'SELF_SCHEMA_CREATE_LOADING';
export const SELF_SCHEMA_CREATE_ERROR = 'SELF_SCHEMA_CREATE_ERROR';

export const ALL_SELF_SCHEMAS_LOADING = 'ALL_SELF_SCHEMAS_LOADING';
export const ALL_SELF_SCHEMAS_GET = 'ALL_SELF_SCHEMAS_GET';
export const ALL_SELF_SCHEMAS_ERROR = 'ALL_SELF_SCHEMAS_ERROR';

export const SELF_SCHEMA_UPDATE_LOADING = 'SELF_SCHEMA_UPDATE_LOADING';
export const SELF_SCHEMA_UPDATE_ERROR = 'SELF_SCHEMA_UPDATE_ERROR';

export const SELF_SCHEMA_DELETE_LOADING = 'SELF_SCHEMA_DELETE_LOADING';

// Self Configurations
export const CLEAR_SELF_CONFIGS_ERRORS = 'CLEAR_CONFIGS_ERRORS';

export const SELF_CONFIG_CREATE_LOADING = 'SELF_CONFIG_CREATE_LOADING';
export const SELF_CONFIG_CREATE_ERROR = 'SELF_CONFIG_CREATE_ERROR';

export const ALL_SELF_CONFIGS_LOADING = 'ALL_SELF_CONFIGS_LOADING';
export const ALL_SELF_CONFIGS_GET = 'ALL_SELF_CONFIGS_GET';
export const ALL_SELF_CONFIGS_ERROR = 'ALL_SELF_CONFIGS_ERROR';

export const SELF_CONFIG_UPDATE_LOADING = 'SELF_CONFIG_UPDATE_LOADING';
export const SELF_CONFIG_UPDATE_ERROR = 'SELF_CONFIG_UPDATE_ERROR';

export const SELF_CONFIG_DELETE_LOADING = 'SELF_CONFIG_DELETE_LOADING';

// Staffs
export const CLEAR_ALL_STAFFS_DATA = 'CLEAR_ALL_STAFFS_DATA';
export const CLEAR_ALL_STAFFS_ERRORS = 'CLEAR_ALL_STAFFS_ERRORS';
export const CLEAR_STAFFS_ERROR = 'CLEAR_STAFFS_ERROR';

export const STAFF_CHECK_LOADING = 'STAFF_CHECK_LOADING';
export const STAFF_CHECK_SUCCESS = 'STAFF_CHECK_SUCCESS';
export const STAFF_CHECK_ERROR = 'STAFF_CHECK_ERROR';

export const STAFF_UPDATE_LOADING = 'STAFF_UPDATE_LOADING';
