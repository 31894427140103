import Axios from 'axios';
import i18next from 'i18next';

import {
	CLEAR_ALL_STAFFS_DATA,
	CLEAR_ALL_STAFFS_ERRORS,
	CLEAR_STAFFS_ERROR,
	STAFF_CHECK_LOADING,
	STAFF_CHECK_SUCCESS,
	STAFF_CHECK_ERROR,
	STAFF_UPDATE_LOADING
} from '../types';

// Utils
import create_query_params from '../../utils/create_query_params';

export const clear_staffs_errors = (key) => dispatch => {
	if (key) {
		dispatch({
			type: CLEAR_STAFFS_ERROR,
			payload: key
		});
	}
	else {
		dispatch({
			type: CLEAR_ALL_STAFFS_ERRORS
		});
	}
}

export const check_user_staff = (filters) => dispatch => {
	dispatch({
		type: STAFF_CHECK_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + '/api/staff/check?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data);
		dispatch({
			type: STAFF_CHECK_SUCCESS,
			payload: res.data
		});

		dispatch({
			type: STAFF_CHECK_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: STAFF_CHECK_ERROR,
			payload: { type: 'check_user_staff', msg: err.message }
		});

		dispatch({
			type: STAFF_CHECK_SUCCESS,
			payload: {}
		});

		dispatch({
			type: STAFF_CHECK_LOADING,
			payload: false
		});
	})
}

export const staff_update = (messageApi, staffId, body) => dispatch => {
	dispatch({
		type: STAFF_UPDATE_LOADING,
		payload: true
	});

	let url = process.env.REACT_APP_SERVER_URL + `/api/staff/${staffId}/update`;

	Axios.put(url, body)
	.then((res) => {
		// console.log(res.data);
		// messageApi.success(i18next.t('actions.delete.successAlert'), 4);
		messageApi.success('Supervisor actualizado correctamente', 4);
		

		dispatch({
			type: STAFF_UPDATE_LOADING,
			payload: false
		});
	}).catch((err) => {
		// console.log(err);
		// messageApi.error(i18next.t('actions.delete.errorAlert'), 4);
		messageApi.error('Error al actualizar el supervisor, intenta de nuevo mas tarde', 4);

		dispatch({
			type: STAFF_UPDATE_LOADING,
			payload: false
		});
	})
}
