import {
	CLEAR_ALL_USERS_DATA,
	CLEAR_ALL_USERS_ERRORS,
	CLEAR_USERS_ERROR,
	SEARCH_USER_LOADING,
	SEARCH_USER_GET,
	SEARCH_USER_ERROR,
	USER_INFO_LOADING,
	USER_INFO_GET,
	USER_INFO_ERROR
} from '../types';

const initialState = {
	user_info_loading: false,
	user_info: {},
	search_user_loading: false,
	users_found: { count: 0, users: [] },
	users_errors: {}
}

export default function usersReducer (state = initialState, action) {
	switch (action.type) {
		case CLEAR_ALL_USERS_ERRORS:
			return {
				...state,
				users_errors: {}
			}
		case CLEAR_USERS_ERROR:
			return {
				...state,
				users_errors: Object.keys(state.users_errors).reduce((acc, key) => {
					if (key !== action.payload) {
						acc[key] = state.users_errors[key];
					}
					return acc;
				}, {})
			}
		case USER_INFO_LOADING:
			return {
				...state,
				user_info_loading: action.payload
			}
		case USER_INFO_GET:
			return {
				...state,
				user_info: action.payload
			}
		case SEARCH_USER_LOADING:
			return {
				...state,
				search_user_loading: action.payload
			}
		case SEARCH_USER_GET:
			return {
				...state,
				users_found: {
					count: action.payload.count,
					users: action.payload.users
				}
			}
		case SEARCH_USER_ERROR:
		case USER_INFO_ERROR:
			return {
				...state,
				users_errors: {
					...state.users_errors, 
					[`${action.payload.type}`]: { msg: action.payload.msg }
				}
			}
		default:
			return state;
	}
}
