import { combineReducers } from 'redux';

// Reducers
import authReducer from './authReducer';
import organizationReducer from './organizationReducer';
import areasReducer from './areasReducer';
import selfReducer from './selfReducer';
import routesReducer from './routesReducer';
import selfErrorsReducer from './selfErrorsReducer';
import selfSchemasReducer from './selfSchemasReducer';
import selfConfigReducer from './selfConfigReducer';
import cyclesReducer from './cyclesReducer';
import usersReducer from './usersReducer';
import staffsReducer from './staffsReducer';

export default combineReducers ({
	auth: authReducer,
	organization: organizationReducer,
	areas: areasReducer,
	self: selfReducer,
	routes: routesReducer,
	self_errors: selfErrorsReducer,
	self_schemas: selfSchemasReducer,
	self_config: selfConfigReducer,
	cycles: cyclesReducer,
	users: usersReducer,
	staffs: staffsReducer
});
