import i18next from 'i18next';

// CYCLE_STATUS_NONE = 0
// CYCLE_STATUS_CREATED = 1
// CYCLE_STATUS_STARTING = 2
// CYCLE_STATUS_AVAILABLE = 3
// CYCLE_STATUS_WAITING = 4
// CYCLE_STATUS_COMPLETED = 5
// CYCLE_STATUS_REMOVED = 6
export const get_cycle_status_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.cycle.none');      break;
		case 1:   desc = i18next.t('statusDesc.cycle.created');   break;
		case 2:   desc = i18next.t('statusDesc.cycle.starting');  break;
		case 3:   desc = i18next.t('statusDesc.cycle.available'); break;
		case 4:   desc = i18next.t('statusDesc.cycle.waiting');   break;
		case 5:   desc = i18next.t('statusDesc.cycle.completed'); break;
		case 6:   desc = i18next.t('statusDesc.cycle.removed');   break;
		default:  desc = '';                                      break;
	}

	return desc;
}

// AREA_STATUS_NONE = 0
// AREA_STATUS_CREATED = 1
// AREA_STATUS_AVAILABLE = 2
// AREA_STATUS_DISABLED = 3
// AREA_STATUS_REMOVED = 4
export const get_route_status_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.route.none');      break;
		case 1:   desc = i18next.t('statusDesc.route.created');   break;
		case 2:   desc = i18next.t('statusDesc.route.available'); break;
		case 3:   desc = i18next.t('statusDesc.route.disabled');  break;
		case 4:   desc = i18next.t('statusDesc.route.removed');   break;
		default:  desc = '';                                      break;
	}

	return desc;
}

// LOAD_STATUS_NONE = 0
// LOAD_STATUS_CREATED = 1
// LOAD_STATUS_REVIEW = 2
// LOAD_STATUS_WAITING = 3
// LOAD_STATUS_PROCESSING = 4
// LOAD_STATUS_GOOD = 5
// LOAD_STATUS_INCOMPLETE = 6
// LOAD_STATUS_BAD = 7
// LOAD_STATUS_REMOVED = 8
// LOAD_STATUS_ERROR = 9
export const get_load_status_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.load.none');       break;
		case 1:   desc = i18next.t('statusDesc.load.created');    break;
		case 2:   desc = i18next.t('statusDesc.load.review');     break;
		case 3:   desc = i18next.t('statusDesc.load.waiting');    break;
		case 4:   desc = i18next.t('statusDesc.load.processing'); break;
		case 5:   desc = i18next.t('statusDesc.load.good');       break;
		case 6:   desc = i18next.t('statusDesc.load.incomplete'); break;
		case 7:   desc = i18next.t('statusDesc.load.bad');        break;
		case 8:   desc = i18next.t('statusDesc.load.removed');    break;
		case 9:   desc = i18next.t('statusDesc.load.error');      break;
		default:  desc = '';                                      break;
	}

	return desc;
}

// LOAD_TYPE_NONE = 0
// LOAD_TYPE_CYCLE = 1
// LOAD_TYPE_SINGLE = 2
// LOAD_TYPE_UPDATE = 3
export const get_load_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.load.none');        break;
		case 1:   desc = i18next.t('loadTypeOptions.cycle');       break;
		case 2:   desc = i18next.t('loadTypeOptions.single');      break;
		case 3:   desc = i18next.t('loadTypeOptions.restructure'); break;
		default:  desc = '';                                       break;
	}

	return desc;
}

// LOAD_SOURCE_NONE = 0
// LOAD_SOURCE_CLOUD = 1
// LOAD_SOURCE_LOCAL = 2
// LOAD_SOURCE_SERVICE = 3
// LOAD_SOURCE_OTHER = 4
export const get_load_source_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.loadSource.none');    break;
		case 1:   desc = i18next.t('statusDesc.loadSource.cloud');   break;
		case 2:   desc = i18next.t('statusDesc.loadSource.local');   break;
		case 3:   desc = i18next.t('statusDesc.loadSource.service'); break;
		case 4:   desc = i18next.t('statusDesc.loadSource.other');   break;
		default:  desc = '';                                         break;
	}

	return desc;
}

// LOAD_SCOPE_NONE = 0
// LOAD_SCOPE_GLOBAL = 1
// LOAD_SCOPE_ORGANIZATION = 2
// LOAD_SCOPE_WORKSPACE = 3
// LOAD_SCOPE_MODULE = 4
export const get_load_scope_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.loadScope.none');         break;
		case 1:   desc = i18next.t('statusDesc.loadScope.global');       break;
		case 2:   desc = i18next.t('statusDesc.loadScope.organization'); break;
		case 3:   desc = i18next.t('statusDesc.loadScope.workspace');    break;
		case 4:   desc = i18next.t('statusDesc.loadScope.module');       break;
		default:  desc = '';                                             break;
	}

	return desc;
}

// LOAD_SCHEMA_NONE = 0
// LOAD_SCHEMA_INT = 1
// LOAD_SCHEMA_FLOAT = 2
// LOAD_SCHEMA_STRING = 3
export const get_load_field_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = i18next.t('statusDesc.loadFieldType.none');   break;
		case 1:   desc = i18next.t('statusDesc.loadFieldType.int');    break;
		case 2:   desc = i18next.t('statusDesc.loadFieldType.float');  break;
		case 3:   desc = i18next.t('statusDesc.loadFieldType.string'); break;
		default:  desc = '';                                           break;
	}

	return desc;
}

// AREA_TYPE_NONE = 0
// AREA_TYPE_ORGANIZATION = 1
// AREA_TYPE_REGION = 2
// AREA_TYPE_ZONE = 3
// AREA_TYPE_DEPOT = 4
// AREA_TYPE_GROUP = 5
// AREA_TYPE_ROUTE = 6
// AREA_TYPE_LOCATION = 7
export const get_area_type_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';   break;
		case 1:   desc = i18next.t('filters.organization');            break;
		case 2:   desc = i18next.t('filters.region');                  break;
		case 3:   desc = i18next.t('filters.zone');                    break;
		case 4:   desc = i18next.t('filters.depot');                   break;
		case 5:   desc = i18next.t('filters.group');                   break;
		case 6:   desc = i18next.t('filters.route');                   break;
		case 7:   desc = i18next.t('statusDesc.areaType.locations');   break;
		default:  desc = 'None';                                       break;
	}

	return desc;
}

// CYCLE_SCOPE_NONE = 0
// CYCLE_SCOPE_COMPLETE = 1
// CYCLE_SCOPE_SELECTED = 2
export const get_cycle_scope_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';                                        break;
		case 1:   desc = i18next.t('statusDesc.cycleScope.complete');   break;
		case 2:   desc = i18next.t('statusDesc.cycleScope.selected');   break;
		default:  desc = 'None';                                        break;
	}

	return desc;
}

// LOAD_VALUE_NONE = 0
// LOAD_VALUE_REGION = 1
// LOAD_VALUE_ZONE = 2
// LOAD_VALUE_DEPOT = 3
// LOAD_VALUE_DATA = 4
// LOAD_VALUE_GROUP = 5
// LOAD_VALUE_ROUTE = 6
// LOAD_VALUE_LOCATION = 7
// LOAD_VALUE_NAME = 8
// LOAD_VALUE_DESCRIPTION = 9
// LOAD_VALUE_LAT = 10
// LOAD_VALUE_LON = 11
export const get_load_value_desc = (status = 0) => {
	let desc = '';

	switch (parseInt(status)) {
		case 0:   desc = 'None';          break;
		case 1:   desc = 'REGION';        break;
		case 2:   desc = 'ZONE';          break;
		case 3:   desc = 'DEPOT';         break;
		case 4:   desc = 'DATA';          break;
		case 5:   desc = 'GROUP';         break;
		case 6:   desc = 'ROUTE';         break;
		case 7:   desc = 'LOCATION';      break;
		case 8:   desc = 'NAME';          break;
		case 9:   desc = 'DESCRIPTION';   break;
		case 10:  desc = 'LAT';           break;
		case 11:  desc = 'LON';           break;
		default:  desc = 'None';          break;
	}

	return desc;
}

// STAFF_TYPE_NONE = 0
// STAFF_TYPE_GENERAL = 1
// STAFF_TYPE_REGION = 2
// STAFF_TYPE_ZONE = 4
// STAFF_TYPE_DEPOT = 8
// STAFF_TYPE_GROUP = 16
// STAFF_TYPE_ROUTE = 32
export const get_staff_type_desc = (status = 0) => {
	switch (parseInt(status)) {
		case 1:
			return 'General';
		case 2:
			return i18next.t('filters.workspace');
		case 4:
			return i18next.t('filters.region');
		case 8:
			return i18next.t('filters.zone');
		case 16:
			return i18next.t('filters.depot');
		case 32:
			return i18next.t('filters.group');
		case 64:
			return i18next.t('filters.route');
		default:
			return 'None';
	}
};

