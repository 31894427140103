import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Card, Col, Form, Result, Row, Select, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';

// Components
import OrgFilters from '../../filters/OrgFilters';
import StaffInfo from './StaffInfo';

// Actions
import { clear_users_errors, search_user } from '../../../redux/actions/usersActions';
import { check_user_staff, clear_staffs_errors } from '../../../redux/actions/staffsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const Staffs = (props) => {
	const { messageApi } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user_info_loading, search_user_loading, users_found } = useSelector(state => state.users);
	
	const { staff_check_loading, user_staff, staffs_errors } = useSelector(state => state.staffs);

	const [orgFilters, setOrgFilters] = useState({ organization: '' });

	const debouncedSearchUser = debounce((dispatch, value) => {
		const filters = {
			organization: orgFilters.organization,
			username: value
		};
		dispatch(search_user(filters));
	}, 500);

	const onSearchUser = (value) => {
		if (value && value.length > 0) {
			const username = value.replace(/-/g, '?');
			debouncedSearchUser(dispatch, username);
		}
		else {
			// TODO: Change this alert to select input
			messageApi.warning('Ingresa un nombre de usuario', 4);
		}
	};

	const checkUserStaff = (userId) => {
		dispatch(clear_staffs_errors('check_user_staff'));
		dispatch(clear_users_errors('get_user_info'));

		const filters = {
			organization: orgFilters.organization,
			user: userId
		};
		dispatch(check_user_staff(filters));
	};

	return (
		<div>
			<Row justify='center'>
				<Col lg={17} md={17} sm={24} xs={24}>
					<Typography.Title className='text-center' level={2}>Supervisores</Typography.Title>

					<OrgFilters
						orgFilters={orgFilters}
						setOrgFilters={setOrgFilters}
						displayFilters={{ organization: true, workspace: false, module: false }}
					/>
					<Form.Item
						style={{ margin: '2rem auto' }}
						label='Buscar usuario'
					>
						<Select
							style={{ width: '100%' }}
							showSearch
							placeholder='Ingresa nombre de usuario'
							optionFilterProp='children'
							filterOption={false}
							onSearch={value => onSearchUser(value)}
							loading={search_user_loading}
							options={users_found.users.map(user => ({ value: user.id, label: user.username }))}
							onChange={value => checkUserStaff(value)}
						/>
					</Form.Item>

					{staff_check_loading && user_info_loading
						?	<div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
								<Spin size='large' />
							</div>
						:	!isEmpty(user_staff)
							?	<StaffInfo
									messageApi={messageApi}
									userStaff={user_staff}
								/>
							:	staffs_errors.check_user_staff
								?	<Card bordered={false}>
										<Result
											status='warning'
											title='Usuario no encontrado'
											subTitle='Este usuario no es un supervisor, intenta con otro.'
										/>
									</Card>
								:	null
					}
				</Col>
			</Row>
		</div>
	)
}

Staffs.propTypes = {
	messageApi: PropTypes.object.isRequired
}

export default Staffs;
