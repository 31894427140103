import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Dropdown, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// Actions
import { auth_token_remove } from '../../redux/actions/authActions';
import { get_organization_info } from '../../redux/actions/organizationActions';

// Utils
import isEmpty from '../../utils/isEmpty';
import check_user_role from '../../utils/check_user_role';

const Header = () => {
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { user } = useSelector(state => state.auth);

	const { organization_info } = useSelector(state => state.organization);

	const _ls = { ...localStorage };

	const [isGodUser, setIsGodUser] = useState(false);

	useEffect(() => {
		dispatch(get_organization_info(user.organization));

		const godUser = check_user_role(user.role);
		setIsGodUser(godUser);
	}, []);

	useEffect(() => {
		if (!isEmpty(organization_info)) {
			localStorage.setItem('organization_name', organization_info.name);
		}
	}, [organization_info]);

	const handleLogout = (e) => {
		e.preventDefault();
		dispatch(auth_token_remove());
	}

	const items = [
		{
			key: '0',
			label: <a href={process.env.REACT_APP_LOGIN + '/account'}>{t('navbar.menu.account')}</a>,
			icon: <i className='bi bi-person-circle dp-item-icon'></i>
		},
		{
			key: '1',
			label: <a href={process.env.REACT_APP_LOGIN + '/links'}>{t('navbar.menu.return')}</a>,
			icon: <i className='bi bi-grid dp-item-icon'></i>
		},
		{
			type: 'divider',
		},
		{
			key: '3',
			danger: true,
			label: <a onClick={(e) => handleLogout(e)}>{t('navbar.menu.logOut')}</a>,
			icon: <i className='bi bi-box-arrow-right dp-item-icon'></i>
		}
	];

	return (
		<Navbar className='navbar' expand='lg' variant='dark'>
			<Container fluid>
				<Navbar.Brand href='/'>
					<img className='d-inline-block align-items-center me-2' height={'30px'} alt='Percepthor Logo'
						src={process.env.PUBLIC_URL + '/assets/percepthor-logo.png'}
					/>
					Percepthor - {_ls.organization_name}
				</Navbar.Brand>
				<Navbar.Toggle aria-controls='navbarScroll' />
				<Navbar.Collapse id='navbarScroll'>
					<Nav className='me-auto' navbarScroll>
						<NavDropdown
							title={t('navbar.options.load.title')}
							id='navbarScrollingDropdown'
							active={window.location.pathname.includes('load')}
						>
							<NavDropdown.Item href='/loads'>{t('navbar.options.load.loads')}</NavDropdown.Item>
							<NavDropdown.Item href='/load-files'>{t('navbar.options.load.load')}</NavDropdown.Item>
							<NavDropdown.Item href='/load-status'>{t('navbar.options.load.status')}</NavDropdown.Item>
						</NavDropdown>
						<Nav.Link href='/routes' active={window.location.pathname.includes('route')}>{t('orgRoutes.title')}</Nav.Link>
						{isGodUser &&
							<Fragment>
								<Nav.Link href='/errors' active={window.location.pathname.includes('/errors')}>{t('errors.title')}</Nav.Link>
								<Nav.Link href='/schemas' active={window.location.pathname.includes('/schemas')}>{t('schemas.title')}</Nav.Link>
								<Nav.Link href='/configurations' active={window.location.pathname.includes('/configurations')}>{t('config.title')}</Nav.Link>
								<Nav.Link href='/cycles' active={window.location.pathname.includes('/cycles')}>{t('cycle.title')}</Nav.Link>
							</Fragment>
						}
						<Nav.Link href='/staffs' active={window.location.pathname.includes('/staffs')}>Supervisores</Nav.Link>
					</Nav>
					<div className='header-dropdown'>
						<Dropdown
							menu={{ items }}
							trigger={[ 'click' ]}
							placement='bottom'
						>
							<a onClick={(e) => e.preventDefault()}>
								<Space>
									<i className='bi bi-person-circle'></i>{user.username}
									<CaretDownOutlined />
								</Space>
							</a>
						</Dropdown>
					</div>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default Header;
