import Axios from 'axios';

import {
	AREAS_GET,
	AREAS_ERROR,
	REGIONS_GET,
	REGIONS_ERROR,
	REGION_INFO_GET,
	REGION_INFO_ERROR,
	ZONES_GET,
	ZONES_ERROR,
	ZONE_INFO_GET,
	ZONE_INFO_ERROR,
	DEPOTS_GET,
	DEPOTS_ERROR,
	DEPOT_INFO_GET,
	DEPOT_INFO_ERROR,
	GROUPS_GET,
	GROUPS_ERROR,
	GROUP_INFO_GET,
	GROUP_INFO_ERROR,
	AREA_FILTERS_SET
} from '../types';

import create_query_params from '../../utils/create_query_params';
import add_key_prop from '../../utils/add_key_prop';

const getAreaType = (areaFilters) => {
	let area = '';  // if there's no area filters

	if (areaFilters.region !== '') {
		area = 'region';
	}
	if (areaFilters.zone !== '') {
		area = 'zone';
	}
	if (areaFilters.depot !== '') {
		area = 'depot';
	}

	return area;
}

const createAreaFilters = (workspace, cycle, areaFilters) => {
	let newAreaFilters = {};

	const isEmpty = Object.values(areaFilters).every(f => f === '');

	if (isEmpty) { // if there's no area filters
		newAreaFilters = {
			workspace: workspace,
			cycle: cycle
		}
	}
	else {
		let area = getAreaType(areaFilters);

		newAreaFilters = {
			cycle: cycle,
			[area]: areaFilters[area]
		}
	}

	return newAreaFilters;
}

export const get_areas = (workspace, cycle, areaFilters = {}) => dispatch => {
	let filters = createAreaFilters(workspace, cycle, areaFilters);
	
	let url = process.env.REACT_APP_SERVER_URL + '/api/areas/overview?';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: AREAS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: AREAS_ERROR,
			payload: { type: 'areas', msg: err.message }
		})
	})
}

export const get_regions = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/areas/regions?skip=0&limit=0&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		const newRegionsArray = add_key_prop(res.data.regions);
		res.data.regions = newRegionsArray;

		dispatch({
			type: REGIONS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: REGIONS_ERROR,
			payload: { type: 'regions', msg: err.message }
		})
	})
}

export const get_region_info = (regionId) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/areas/regions/${regionId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: REGION_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: REGION_INFO_ERROR,
			payload: { type: 'region_info', msg: err.message }
		})
	})
}

export const get_zones = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/areas/zones?skip=0&limit=0&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		const newZonesArray = add_key_prop(res.data.zones);
		res.data.zones = newZonesArray;

		dispatch({
			type: ZONES_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ZONES_ERROR,
			payload: { type: 'zones', msg: err.message }
		})
	})
}

export const get_zone_info = (zoneId) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/areas/zones/${zoneId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: ZONE_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: ZONE_INFO_ERROR,
			payload: { type: 'zone_info', msg: err.message }
		})
	})
}

export const get_depots = (filters) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/areas/depots?skip=0&limit=0&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		const newDepotsArray = add_key_prop(res.data.depots);
		res.data.depots = newDepotsArray;

		dispatch({
			type: DEPOTS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: DEPOTS_ERROR,
			payload: { type: 'depots', msg: err.message }
		})
	})
}

export const get_depot_info = (depotId) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/areas/depots/${depotId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: DEPOT_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: DEPOT_INFO_ERROR,
			payload: { type: 'depot_info', msg: err.message }
		})
	})
}

export const get_groups = (filters, routeGroupId) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + '/api/areas/groups?skip=0&limit=0&';
	let query = create_query_params(filters);
	url += query;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		const newGroupsArray = add_key_prop(res.data.groups);
		res.data.groups = newGroupsArray.filter((group) => group._id.$oid !== routeGroupId);

		dispatch({
			type: GROUPS_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: GROUPS_ERROR,
			payload: { type: 'groups', msg: err.message }
		})
	})
}

export const get_group_info = (groupId) => dispatch => {
	let url = process.env.REACT_APP_SERVER_URL + `/api/areas/groups/${groupId}/info`;

	Axios.get(url)
	.then((res) => {
		// console.log(res.data)
		dispatch({
			type: GROUP_INFO_GET,
			payload: res.data
		})
	}).catch((err) => {
		// console.log(err);
		dispatch({
			type: GROUP_INFO_ERROR,
			payload: { type: 'groups', msg: err.message }
		})
	})
}

export const set_area_filters = (areaFilters) => dispatch => {
	dispatch({
		type: AREA_FILTERS_SET,
		payload: areaFilters
	});
}
