import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Button, Card, Checkbox, Col, Descriptions, Divider, Form, Row, Select, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { get_user_info } from '../../../redux/actions/usersActions';
import { get_workspaces } from '../../../redux/actions/organizationActions';
import { get_depot_info, get_depots, get_group_info, get_groups, get_region_info, get_regions, get_zone_info, get_zones } from '../../../redux/actions/areasActions';
import { get_routes } from '../../../redux/actions/routesActions';
import { staff_update } from '../../../redux/actions/staffsActions';

// Utils
import isEmpty from '../../../utils/isEmpty';
import { get_staff_type_desc } from '../../../utils/get_status_desc';
import { create_select_options } from '../../../utils/create_select_options';

const StaffInfo = (props) => {
	const { messageApi, userStaff } = props;
	
	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { workspaces } = useSelector(state => state.organization);

	const { regions, region_info, zones, zone_info, depots, depot_info, groups, group_info } = useSelector(state => state.areas);

	const { user_info, users_errors } = useSelector(state => state.users);

	const [staffType, setStaffType] = useState(null);
	const [orgFilters, setOrgFilters] = useState({ organization: '', workspace: '' });
	const [areaFilters, setAreaFilters] = useState({ region: '', zone: '', depot: '', group: '', route: '' });
	const [loadingAreasOptions, setLoadingAreasOptions] = useState(true);
	const [areasOptions, setAreasOptions] = useState([]);
	console.log('areasOptions', areasOptions);

	const [form] = Form.useForm();

	useEffect(() => {
		if (!isEmpty(userStaff)) {
			setOrgFilters({ organization: userStaff?.organization?.$oid, workspace: userStaff?.workspaces[0]?.$oid });
			dispatch(get_user_info(userStaff?.user?.$oid));
			dispatch(get_workspaces({ organization: userStaff?.organization?.$oid }));

			form.setFieldsValue({ staff_type: userStaff?.staff_type });
			setStaffType(userStaff?.staff_type);

			if (userStaff?.staff_type === 4) {
				dispatch(get_region_info(userStaff?.regions[0]?.$oid));
				form.setFieldsValue({ areas: userStaff?.regions.map((region) => region?.$oid) });
			}
			else if (userStaff?.staff_type === 8) {
				dispatch(get_zone_info(userStaff?.zones[0]?.$oid));
				form.setFieldsValue({ areas: userStaff?.zones.map((zone) => zone?.$oid) });
			}
			else if (userStaff?.staff_type === 16) {
				dispatch(get_depot_info(userStaff?.depots[0]?.$oid));
				form.setFieldsValue({ areas: userStaff?.depots.map((depot) => depot?.$oid) });
			}
			else if (userStaff?.staff_type === 32) {
				dispatch(get_group_info(userStaff?.groups[0]?.$oid));
				form.setFieldsValue({ areas: userStaff?.groups.map((group) => group?.$oid) });
			}
		}
	}, [userStaff]);

	const createFilters = (orgFilters, areaFilters) => {
		let filters = { organization: orgFilters.organization, workspace: orgFilters.workspace };

		for (const key in areaFilters) {
			if (areaFilters[key] !== '') {
				filters[key] = areaFilters[key];
			}
		}

		return filters;
	}

	useEffect(() => {
		if (orgFilters.organization !== '' && orgFilters.workspace !== '') {
			const filters = createFilters(orgFilters, areaFilters);

			dispatch(get_regions(filters));
			dispatch(get_zones(filters));
			dispatch(get_depots(filters));
			dispatch(get_groups(filters));
			// dispatch(get_routes({ ...filters, module: orgFilters.module, skip: 0, limit: 10 }));
		}
	}, [orgFilters, areaFilters, staffType]);

	useEffect(() => {
		if (regions.count > 0 && staffType === 4) {
			form.setFieldsValue({
				region: null,
				zone: null,
				depot: null,
				group: null
			});
			setAreasOptions(regions.regions.map((region) => ({ value: region._id.$oid, name: region.name })));
			stopLoadingAreasOptions();
		}
	}, [regions, staffType]);

	useEffect(() => {
		if (!isEmpty(region_info)) {
			form.setFieldsValue({
				region: null,
				zone: null,
				depot: null,
				group: null
			});
			setAreaFilters({ region: '', zone: '', depot: '', group: '', route: '' });
		}
	}, [region_info]);

	useEffect(() => {
		if (zones.count > 0 && staffType === 8) {
			form.setFieldsValue({
				zone: null,
				depot: null,
				group: null
			});
			setAreasOptions(zones.zones.map((zone) => ({ value: zone._id.$oid, name: zone.name })));
			stopLoadingAreasOptions();
		}
	}, [zones, staffType]);

	useEffect(() => {
		if (!isEmpty(zone_info)) {
			form.setFieldsValue({
				region: zone_info?.region?.$oid,
				zone: null,
				depot: null,
				group: null
			});
			setAreaFilters({ region: zone_info?.region?.$oid, zone: '', depot: '', group: '', route: '' });
		}
	}, [zone_info]);

	useEffect(() => {
		if (depots.count > 0 && staffType === 16) {
			form.setFieldsValue({
				depot: null,
				group: null
			});
			setAreasOptions(depots.depots.map((depot) => ({ value: depot._id.$oid, name: depot.description })));
			stopLoadingAreasOptions();
		}
	}, [depots, staffType]);

	useEffect(() => {
		if (!isEmpty(depot_info)) {
			form.setFieldsValue({
				region: depot_info?.region?.$oid,
				zone: depot_info?.zone?.$oid,
				depot: null,
				group: null
			});
			setAreaFilters({ region: depot_info?.region?.$oid, zone: depot_info?.zone?.$oid, depot: '', group: '', route: '' });
		}
	}, [depot_info]);

	useEffect(() => {
		if (groups.count > 0 && staffType === 32) {
			form.setFieldsValue({
				group: null
			});
			setAreasOptions(groups.groups.map((group) => ({ value: group._id.$oid, name: group.name })));
			stopLoadingAreasOptions();
		}
	}, [groups, staffType]);

	useEffect(() => {
		if (!isEmpty(group_info)) {
			form.setFieldsValue({
				region: group_info?.region?.$oid,
				zone: group_info?.zone?.$oid,
				depot: group_info?.depot?.$oid,
				group: null
			});
			setAreaFilters({ region: group_info?.region?.$oid, zone: group_info?.zone?.$oid, depot: group_info?.depot?.$oid, group: '', route: '' });
		}
	}, [group_info]);

	// Function to handle the selected checkbox
	const handleWorkspaceChange = (value) => {
		// If it's already selected, unselect it; if not, select it
		setOrgFilters({ ...orgFilters, workspace: orgFilters.workspace === value ? '' : value });
		form.setFieldsValue({ region: null, zone: null, depot: null, group: null, areas: [] });
		get_regions({ organization: orgFilters.organization, workspace: value });
	};
	
	const stopLoadingAreasOptions = () => {
		setTimeout(() => {
			setLoadingAreasOptions(false);
		}, 2000);
	}

	const onValuesChange = (changedValues, allValues) => {
		if (changedValues.staff_type) {
			setStaffType(changedValues.staff_type);
		}

		if (changedValues.region) {
			dispatch(get_zones({ ...orgFilters, region: changedValues.region }));
		}

		if (changedValues.zone) {
			dispatch(get_depots({ ...orgFilters, zone: changedValues.zone }));
		}

		if (changedValues.depot) {
			dispatch(get_groups({ ...orgFilters, depot: changedValues.depot }));
		}
	}

	const onFinish = (values) => {
		const body = {
			email: '',
			username: user_info?.username,
			status: 0,
			permissions_type: [userStaff?.permissions?.$oid],
			organization: orgFilters.organization,
			staff_type: staffType,
			presets: userStaff?.presets !== undefined ? userStaff?.presets?.map((preset) => preset?.$oid) : [],
			actions: [],
			workspaces: [orgFilters.workspace],
			regions: staffType === 4 ? values.areas : [],
			zones: staffType === 8 ? values.areas : [],
			depots: staffType === 16 ? values.areas : [],
			groups: staffType === 32 ? values.areas : [],
			routes: [],
			locations: []
		}

		dispatch(staff_update(messageApi, userStaff?._id?.$oid, body));
	}

	return (
		<Card bordered={false}>
			<Typography.Title
				level={3}
				copyable={{
					text: user_info?._id?.$oid,
					tooltips: [t('copyableText.copyId'), t('copyableText.copiedId')],
				}}
			>
				{user_info?.name}
			</Typography.Title>
			<Descriptions
				items={[
					{
						key: 'username',
						label: 'Nombre de usuario:',
						children: user_info?.username
					},
					// {
					// 	key: 'staff_type',
					// 	label: 'Tipo de supervisor:',
					// 	children: get_staff_type_desc(staffType)
					// },
					{
						key: 'date',
						label: 'Fecha de creacion:',
						children: new Date(user_info?.date?.$date).toLocaleString(localStorage.getItem('i18nextLng'), { hour12: true })
					}
				]}
			/>
			<Divider />
			<Typography.Title level={5}>Permisos</Typography.Title>
			<Form
				form={form}
				onValuesChange={onValuesChange}
				onFinish={onFinish}
			>
				<Form.Item
					label='Tipo de supervisor'
					name='staff_type'
				>
					<Select
						showSearch
						placeholder='Selecciona un tipo de supervisor'
						optionFilterProp='children'
						filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
						options={[
							{ value: 1, label: get_staff_type_desc(1) }, // GENERAL
							{ value: 2, label: get_staff_type_desc(2) }, // WORKSPACE
							{ value: 4, label: get_staff_type_desc(4) }, // REGION
							{ value: 8, label: get_staff_type_desc(8) }, // ZONE
							{ value: 16, label: get_staff_type_desc(16) }, // DEPOT
							{ value: 32, label: get_staff_type_desc(32) }, // GROUP
							// { value: 64, label: get_staff_type_desc(64) } // ROUTE
						]}
					/>
				</Form.Item>
				<Divider />
				<Typography.Title level={5}>Workspace</Typography.Title>
				<Form.Item>
					<Checkbox.Group
						style={{ width: '100%' }}
						value={orgFilters.workspace ? [orgFilters.workspace] : []}
					>
						<Row gutter={[16, 16]} align='middle'>
							{create_select_options(workspaces, 'name').map((option) => (
								<Col
									key={option.value}
									xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}
								>
									<Checkbox
										value={option.value}
										checked={orgFilters.workspace === option.value}
										onChange={() => handleWorkspaceChange(option.value)}
									>
										{option.label}
									</Checkbox>
								</Col>
							))}
						</Row>
					</Checkbox.Group>
				</Form.Item>
				<Divider />
				<Typography.Title level={5}>{t('filters.areas')}</Typography.Title>
				<Row gutter={[16, 16]} align='middle'>
					<Col xs={24} sm={24} md={24} lg={6} xl={6}>
						<Form.Item
							label={t('filters.region')}
							name='region'
							labelCol={{ span: 24 }}
						>
							<Select
								showSearch
								placeholder='Selecciona una region'
								optionFilterProp='children'
								disabled={staffType <= 4}
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_select_options(regions.regions, 'name')}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={6} xl={6}>
						<Form.Item
							label={t('filters.zone')}
							name='zone'
							labelCol={{ span: 24 }}
						>
							<Select
								showSearch
								placeholder='Selecciona una zona'
								optionFilterProp='children'
								disabled={staffType <= 8}
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_select_options(zones.zones, 'name')}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={6} xl={6}>
						<Form.Item
							label={t('filters.depot')}
							name='depot'
							labelCol={{ span: 24 }}
						>
							<Select
								showSearch
								placeholder='Selecciona un depot'
								optionFilterProp='children'
								disabled={staffType <= 16}
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_select_options(depots.depots, 'description')}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={24} md={24} lg={6} xl={6}>
						<Form.Item
							label={t('filters.group')}
							name='group'
							labelCol={{ span: 24 }}
						>
							<Select
								showSearch
								placeholder='Selecciona un grupo'
								optionFilterProp='children'
								disabled={staffType <= 32}
								filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
								options={create_select_options(groups.groups, 'name')}
							/>
						</Form.Item>
					</Col>
				</Row>
				{loadingAreasOptions
					?	<div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
							<Spin size='large' />
						</div>
					:	<Form.Item
							name='areas'
						>
							<Checkbox.Group
								style={{ width: '100%', marginTop: '1rem' }}
							>
								<Row gutter={[16, 16]} align='middle'>
									{areasOptions.map((option) => (
										<Col
											key={option.value}
											xs={24} sm={12} md={8} lg={6} xl={6} xxl={4}
										>
											<Checkbox
												value={option.value}
											>
												{option.name}
											</Checkbox>
										</Col>
									))}
								</Row>
							</Checkbox.Group>
						</Form.Item>
				}
				<Button
					type='primary'
					block
					htmlType='submit'
				>
					{t('locations.finishChanges')}
				</Button>
			</Form>
		</Card>
	)
}

StaffInfo.propTypes = {
	userStaff: PropTypes.object.isRequired
}

export default StaffInfo;
